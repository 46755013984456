import {
  Divider,
  Form,
  Row,
  Typography,
  Col,
  Input,
  Button,
  Switch,
  TimePicker,
  Modal,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  useSignInOutInformationCreateMutation,
  useSignInOutInformationDeleteMutation,
} from "../../../features/api/adminApi/sign_in_out";
import { ToastBoxMessage } from "../../../global/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { logOut, setUser } from "../../../features/slice/authSlice";
import Item from "antd/es/list/Item";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  sign_in: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      from_time: yup.date().nullable().required("Start time is required"),
      to_time: yup.date().nullable().required("End time is required"),
      yes_or_no: yup.boolean(),
    })
  ),
  sign_out: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      from_time: yup.date().nullable().required("Start time is required"),
      to_time: yup.date().nullable().required("End time is required"),
      yes_or_no: yup.boolean(),
    })
  ),
});

// const validationSchema = yup.object().shape({
//   sign_in: yup.array().of(
//     yup.object().shape({
//       question: yup.string().required("Question is required"),
//       from_time: yup.string().when("question", {
//         is: (val) => val && val.trim() !== "",
//         then: yup.string().required("From Time is required"),
//         otherwise: yup.string().nullable(),
//       }),
//       to_time: yup.string().when("question", {
//         is: (val) => val && val.trim() !== "",
//         then: yup.string().required("To Time is required"),
//         otherwise: yup.string().nullable(),
//       }),
//       yes_or_no: yup.boolean(),
//     })
//   ),
//   sign_out: yup.array().of(
//     yup.object().shape({
//       question: yup.string().required("Question is required"),
//       from_time: yup.string().when("question", {
//         is: (val) => val && val.trim() !== "",
//         then: yup.string().required("From Time is required"),
//         otherwise: yup.string().nullable(),
//       }),
//       to_time: yup.string().when("question", {
//         is: (val) => val && val.trim() !== "",
//         then: yup.string().required("To Time is required"),
//         otherwise: yup.string().nullable(),
//       }),
//       yes_or_no: yup.boolean(),
//     })
//   ),
// });
const SignInOutInformations = (props) => {
  const [
    signInOutInformationCreate,
    {
      isError: findIsError,
      error: findError,
      isLoading: isCreating,
      isFetching: isCreatingFetching,
    },
  ] = useSignInOutInformationCreateMutation();

  const [
    signInOutInformationDelete,
    {
      isError: findDeleteError,
      error: findError1,
      isLoading: isDeleting,
      isFetching: isDeletingFetching,
    },
  ] = useSignInOutInformationDeleteMutation();

  const [toasterBox, setToasterBox] = useState(false);
  const [toasterMsg, setToasterMsg] = useState({});
  const [toasterBgcolor, setToasterBgcolor] = useState("");
  const dispatch = useDispatch();
  const { profileData, refetch } = props;
  const authSliceData = useSelector((state) => state.auth);
  const handleToasterOk = () => {
    setToasterBox(false);
  };

  const handleToasterCancel = () => {
    setToasterBox(false);
  };

  const { control, handleSubmit, watch, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sign_in: [
        {
          question: "",
          from_time: null,
          to_time: null,
          yes_or_no: false,
          type: "sign_in",
        },
      ],
      sign_out: [
        {
          question: "",
          from_time: null,
          to_time: null,
          yes_or_no: false,
          type: "sign_out",
        },
      ],
    },
  });

  const {
    fields: signInFields,
    append: appendSignIn,
    remove: removeSignIn,
  } = useFieldArray({
    control,
    name: "sign_in",
    keyName: "_id",
  });

  const {
    fields: signOutFields,
    append: appendSignOut,
    remove: removeSignOut,
  } = useFieldArray({
    control,
    name: "sign_out",
    keyName: "_id",
  });
  const [deleted_ids, setDeleted_ids] = useState([]);

  const signInFieldsWatch = watch("sign_in");
  const signOutFieldsWatch = watch("sign_out");

  // console.log(signInFieldsWatch, "signInFieldsWatch");
  const isSaveDisabled = signInFieldsWatch.every(
    (item) => item.question.trim() === ""
  );

  const handleAddSignIn = () => {
    // Check if there are empty fields before appending new ones
    const hasEmptyFields = signInFieldsWatch.some(
      (item) => !item.question.trim() || !item.from_time || !item.to_time
    );

    if (!hasEmptyFields) {
      appendSignIn({
        question: "",
        from_time: null,
        to_time: null,
        yes_or_no: false,
        type: "sign_in",
      });
    } else {
      // Optionally, you can show a message to the user
      console.log("Please fill in the existing fields before adding new ones.");
    }
  };

  const handleAddSignOut = () => {
    // Check if there are empty fields before appending new ones
    const hasEmptyFields = signOutFieldsWatch.some(
      (item) => !item.question.trim() || !item.from_time || !item.to_time
    );

    if (!hasEmptyFields) {
      appendSignOut({
        question: "",
        from_time: null,
        to_time: null,
        yes_or_no: false,
        type: "sign_out",
      });
    } else {
      // Optionally, you can show a message to the user
      console.log("Please fill in the existing fields before adding new ones.");
    }
  };

  const handleDelete = async (index, id, item, type) => {
    console.log(type, "type");
    if (item?.company_id) {
      setDeleted_ids((prevIds) => [...prevIds, id]);
    }
    if (type === "in") {
      removeSignIn(index);
    }
    if (type === "out") {
      removeSignOut(index);
    }
  };

  // const newFunction = () => {
  //   const signInIndexesToRemove = [];
  //   signInFields.forEach((item, i) => {
  //     if (
  //       item.question === "" &&
  //       item.from_time === "" &&
  //       item.to_time === ""
  //     ) {
  //       signInIndexesToRemove.push(i);
  //     }
  //   });

  //   if (signInIndexesToRemove.length > 0) {
  //     removeSignIn(signInIndexesToRemove);
  //   }
  //   console.log(signInIndexesToRemove, "kkkvvvv");
  //   const signOutIndexesToRemove = [];
  //   signOutFields.forEach((item, i) => {
  //     if (
  //       item.question === "" &&
  //       item.from_time === "" &&
  //       item.to_time === ""
  //     ) {
  //       signOutIndexesToRemove.push(i);
  //     }
  //   });

  //   if (signOutIndexesToRemove.length > 0) {
  //     removeSignOut(signOutIndexesToRemove);
  //   }
  //   console.log(signOutIndexesToRemove, "kkk");
  // };
  console.log(signInFields, "signInFields");
  console.log(signOutFields, "signOutFields");

  const onSubmit = async (data) => {
    console.log(data, "data");

    try {
      // const filteredSignIn = data.sign_in.filter(
      //   (item) =>
      //     item.question !== "" && item.from_time !== "" && item.to_time !== ""
      // );

      // const filteredSignOut = data.sign_out.filter(
      //   (item) =>
      //     item.question !== "" && item.from_time !== "" && item.to_time !== ""
      // );
      // newFunction();

      const formattedData = {
        ...data,
        // sign_in: signInFields.map((item) => ({
        //   ...item,
        //   from_time: item.from_time
        //     ? dayjs(item.from_time).format("hh:mm A")
        //     : "",
        //   to_time: item.to_time ? dayjs(item.to_time).format("hh:mm A") : "",
        // })),
        sign_in: data.sign_in.map((item) => {
          console.log(item, "before formatting sign_in");
          const formattedItem = {
            ...item,
            from_time: item.from_time
              ? dayjs(item.from_time).format("hh:mm A")
              : item.from_time,
            to_time: item.to_time
              ? dayjs(item.to_time).format("hh:mm A")
              : item.to_time,
          };
          console.log(formattedItem, "after formatting sign_in");
          return formattedItem;
        }),
        // sign_out: signOutFields.map((item) => ({
        //   ...item,
        //   from_time: item.from_time
        //     ? dayjs(item.from_time).format("hh:mm A")
        //     : "",
        //   to_time: item.to_time ? dayjs(item.to_time).format("hh:mm A") : "",
        // })),
        sign_out: data.sign_out.map((item) => {
          console.log(item, "before formatting sign_out");
          const formattedItem = {
            ...item,
            from_time: item.from_time
              ? dayjs(item.from_time).format("hh:mm A")
              : item.from_time,
            to_time: item.to_time
              ? dayjs(item.to_time).format("hh:mm A")
              : item.to_time,
          };
          console.log(formattedItem, "after formatting sign_out");
          return formattedItem;
        }),
      };

      console.log(formattedData, "formattedData");

      const newData = {
        ...formattedData,
        deleted_ids: deleted_ids,
      };

      if (deleted_ids.length > 0) {
        const resDelete = await signInOutInformationCreate(newData).unwrap();
        const deleteSuccessMessage = ToastBoxMessage(
          resDelete?.message,
          "success"
        );
        setToasterMsg({ type: "success", message: deleteSuccessMessage });
        setToasterBgcolor("#07bc0c");
        setToasterBox(true);
        setTimeout(() => {
          setToasterBox(false);
        }, 3000);
        setDeleted_ids([]);
        refetch();
      } else {
        const resCreate = await signInOutInformationCreate(
          formattedData
        ).unwrap();
        const successMessage = ToastBoxMessage(resCreate?.message, "success");
        setToasterMsg({ type: "success", message: successMessage });
        setToasterBgcolor("#07bc0c");
        setToasterBox(true);
        setTimeout(() => {
          setToasterBox(false);
        }, 3000);
        refetch();
      }
    } catch (err) {
      console.log(err, "error");
      const errorMessage = ToastBoxMessage("", "error", err?.data?.error);
      setToasterMsg({ type: "error", message: errorMessage });
      setToasterBgcolor("#e74c3c");
      setToasterBox(true);
      setTimeout(() => {
        setToasterBox(false);
      }, 3000);
    }
  };

  const handleCancel = async () => {
    reset();
    refetch();
  };

  useEffect(() => {
    if (profileData) {
      const signIn = profileData?.sign_in.map((item) => ({
        ...item,
        from_time: item.from_time ? dayjs(item.from_time, "hh:mm A") : null,
        to_time: item.to_time ? dayjs(item.to_time, "hh:mm A") : null,
      }));

      const signOut = profileData?.sign_out.map((item) => ({
        ...item,
        from_time: item.from_time ? dayjs(item.from_time, "hh:mm A") : null,
        to_time: item.to_time ? dayjs(item.to_time, "hh:mm A") : null,
      }));

      reset({
        sign_in: signIn.length
          ? signIn
          : [
              {
                question: "",
                from_time: null,
                to_time: null,
                yes_or_no: false,
                type: "sign_in",
              },
            ],
        sign_out: signOut.length
          ? signOut
          : [
              {
                question: "",
                from_time: null,
                to_time: null,
                yes_or_no: false,
                type: "sign_out",
              },
            ],
      });
    }
  }, [profileData, reset]);

  return (
    <>
      <Row gutter={[10, 20]}>
        <Col span={24}>
          <Typography.Title level={4}>Sign In Informations</Typography.Title>
          <Divider style={{ margin: 0 }} />
        </Col>
      </Row>
      <Spin
        spinning={isCreating === true || isDeleting === true ? true : false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {signInFieldsWatch.map((item, index, type) => (
            <Row
              key={item._id}
              style={{ marginBottom: "16px" }}
              gutter={[10, 10]}
            >
              <Col span={8}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                    // flexDirection: "column",
                  }}
                >
                  <label
                    htmlFor={`sign_in[${index}].question`}
                    style={{ fontSize: "12px", marginRight: "10px" }}
                  >
                    Enter Question
                  </label>
                  <Controller
                    name={`sign_in[${index}].question`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        {" "}
                        <Input
                          {...field}
                          id={`sign_in[${index}].question`}
                          placeholder="Enter Question"
                          style={{ flex: 1, width: "150px" }}
                          className="input-new"
                        />
                        {fieldState.error && (
                          <p style={{ color: "red" }}>
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </Col>

              <Col lg={10} md={16}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginLeft: "-20px",
                  }}
                  className="new-time-picker"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label
                      htmlFor={`sign_in[${index}].from_time`}
                      style={{ fontSize: "12px", marginRight: "5px" }}
                      className="label-timepicker"
                    >
                      From Time
                    </label>
                    <Controller
                      name={`sign_in[${index}].from_time`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "120px",
                          }}
                          className="new-timepicker"
                        >
                          {" "}
                          {/* <TimePicker.RangePicker
                          {...field}
                          onChange={(value) => field.onChange(value)}
                        /> */}
                          <TimePicker {...field} format="hh:mm A" />
                          {fieldState.error && (
                            <p style={{ color: "red" }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  {/* </Col>
              <Col span={6}> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label
                      htmlFor={`sign_in[${index}].to_time`}
                      style={{ fontSize: "12px", marginRight: "5px" }}
                      className="label-timepicker"
                    >
                      To Time
                    </label>
                    <Controller
                      name={`sign_in[${index}].to_time`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "113px",
                          }}
                          className="new-timepicker"
                        >
                          {" "}
                          {/* <TimePicker.RangePicker
                          {...field}
                          onChange={(value) => field.onChange(value)}
                        /> */}
                          <TimePicker {...field} format="hh:mm A" />
                          {fieldState.error && (
                            <p style={{ color: "red" }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </Col>

              <Col span={4}>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    marginBottom: "16px",
                    marginTop: "8px",
                    // justifyContent: "center",
                  }}
                >
                  <label
                    htmlFor={`sign_in[${index}].yes_or_no`}
                    style={{ marginRight: "4px", fontSize: "12px" }}
                  >
                    Yes/No
                  </label>
                  <Controller
                    name={`sign_in[${index}].yes_or_no`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        {" "}
                        <Switch {...field} checked={field.value} />
                        {fieldState.error && (
                          <p style={{ color: "red" }}>
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </Col>

              <Col lg={2} md={4} style={{ marginTop: "11px" }}>
                <PlusSquareOutlined
                  // onClick={() =>
                  //   appendSignIn({
                  //     question: "",
                  //     from_time: "",
                  //     to_time: "",
                  //     yes_or_no: false,
                  //     type: "sign_in",
                  //   })
                  // }
                  onClick={handleAddSignIn}
                  style={{ marginLeft: "8px", color: "red" }}
                />

                <MinusSquareOutlined
                  onClick={() =>
                    handleDelete(index, item.id, item, (type = "in"))
                  }
                  style={{ marginLeft: "8px", color: "red" }}
                />
              </Col>
            </Row>
          ))}

          <Row style={{ height: "100%" }} gutter={[10, 20]}>
            <Col span={24}>
              <Typography.Title level={4}>
                Sign Out Informations
              </Typography.Title>
              <Divider style={{ margin: 0 }} />
            </Col>
          </Row>

          {signOutFieldsWatch.map((item, index, type) => (
            <Row
              key={item.id}
              style={{ marginBottom: "16px" }}
              gutter={[10, 10]}
            >
              <Col span={8}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <label
                    htmlFor={`sign_out[${index}].question`}
                    style={{ fontSize: "12px", marginRight: "5px" }}
                  >
                    Enter Question
                  </label>
                  <Controller
                    name={`sign_out[${index}].question`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        {" "}
                        <Input
                          {...field}
                          id={`sign_out[${index}].question`}
                          placeholder="Enter Question"
                          style={{ flex: 1, width: "150px" }}
                        />
                        {fieldState.error && (
                          <p style={{ color: "red" }}>
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </Col>

              <Col lg={10} md={16}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginLeft: "-20px",
                  }}
                  className="new-time-picker"
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label
                      htmlFor={`sign_out[${index}].from_time`}
                      style={{ fontSize: "12px", marginRight: "10px" }}
                      className="label-timepicker"
                    >
                      From Time
                    </label>
                    <Controller
                      name={`sign_out[${index}].from_time`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "120px",
                          }}
                          className="new-timepicker"
                        >
                          {" "}
                          {/* <TimePicker.RangePicker
                          {...field}
                          onChange={(value) => field.onChange(value)}
                        /> */}
                          <TimePicker {...field} format="hh:mm A" />
                          {fieldState.error && (
                            <p style={{ color: "red" }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  {/* </Col> */}
                  {/* <Col span={6}> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label
                      htmlFor={`sign_out[${index}].to_time`}
                      style={{ fontSize: "12px", marginRight: "5px" }}
                      className="label-timepicker"
                    >
                      To Time
                    </label>
                    <Controller
                      name={`sign_out[${index}].to_time`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "113px",
                          }}
                          className="new-timepicker"
                        >
                          {" "}
                          {/* <TimePicker.RangePicker
                          {...field}
                          onChange={(value) => field.onChange(value)}
                        /> */}
                          <TimePicker {...field} format="hh:mm A" />
                          {fieldState.error && (
                            <p style={{ color: "red" }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </Col>

              <Col span={4}>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    marginBottom: "16px",
                    marginTop: "8px",
                    // justifyContent: "center",
                  }}
                >
                  <label
                    htmlFor={`sign_out[${index}].yes_or_no`}
                    style={{ marginRight: "4px", fontSize: "12px" }}
                  >
                    Yes/No
                  </label>
                  <Controller
                    name={`sign_out[${index}].yes_or_no`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        {" "}
                        <Switch {...field} checked={field.value} />
                        {fieldState.error && (
                          <p style={{ color: "red" }}>
                            {fieldState.error.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </Col>

              <Col lg={2} md={2} style={{ marginTop: "11px" }}>
                <PlusSquareOutlined
                  // onClick={() =>
                  //   appendSignOut({
                  //     question: "",
                  //     // timeRange: [null, null],
                  //     from_time: "",
                  //     to_time: "",
                  //     yes_or_no: false,
                  //     type: "sign_out",
                  //   })
                  // }
                  onClick={handleAddSignOut}
                  style={{ marginLeft: "8px", color: "red" }}
                />

                <MinusSquareOutlined
                  onClick={() =>
                    handleDelete(index, item.id, item, (type = "out"))
                  }
                  style={{ marginLeft: "8px", color: "red" }}
                />
              </Col>
            </Row>
          ))}

          <Col span={24} className="employee_view_btn">
            <Form.Item>
              <Button
                // loading={editLoading}
                type="primary"
                // htmlType="submit"
                className="saveButtonPrimary1"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                // loading={editLoading}
                type="primary"
                htmlType="submit"
                className="saveButtonPrimary"
                icon={<CheckOutlined />}
                // disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </form>
      </Spin>
      {toasterBox ? (
        <Modal
          style={{ borderRadius: "15px" }}
          // title="toaster box"
          open={toasterBox}
          onOk={handleToasterOk}
          onCancel={handleToasterCancel}
          className="toaster-modal"
          footer={[]}
          width={300}
          height={"auto"}
          // toasterMsg.type === "success" ? "#07bc0c" : "#e74c3c"
          centered
        >
          <Row
            style={{
              background: toasterBgcolor,
              padding: "20px",
              color: "white",
            }}
          >
            <Col span={2}>
              {toasterMsg.type === "error" ? (
                <ExclamationCircleOutlined style={{ fontSize: "15px" }} />
              ) : (
                <CheckCircleOutlined style={{ fontSize: "15px" }} />
              )}
            </Col>
            <Col span={20}>{toasterMsg?.message}</Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};

export default SignInOutInformations;
